/* my additions from another website */
input[type="email"].reg,
input[type="text"].reg,
input[type="number"].reg,
textarea.reg,
select.reg {
    width: 500px;
}

label.reg {
    margin-top: 1rem;
}

label.checkbox.reg {
    font-weight: normal;
}

button[type="submit"].reg {
    margin-top: 1rem;
}
/* end of additions */


html.reg {
    box-sizing: border-box;
    overflow-y: scroll;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
    /* BETTER FONT SMOOTHING - https://gist.github.com/hsleonis/55712b0eafc9b25f1944 */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

*.reg,
*.reg:before,
*.reg:after {
    box-sizing: inherit;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #f2f4f8;
}

::-webkit-scrollbar-thumb {
    background-color: #141213;
    border: 2px solid #262626;
}

a.reg {
    color: rebeccapurple;
    text-decoration: none;
}

a.reg:active,
a.reg:focus,
a.reg:hover {
     text-decoration: underline;
 }

body.reg {
    scroll-behavior: smooth;

    background-color: #f2f4f8;
    font-size: 1.6rem;
    line-height: 1.5;
}

/* Common base styles for the site */
img.reg,
svg.reg,
video.reg {
    max-width: 100%;
}
