.custom-tooltip {
  position: absolute;
  display: flex;
  align-items: center;
  width: 560px;
  height: 46px;
  border: 1px solid #000000;
  overflow: hidden;
  transition: opacity 1s;
  background-color: #fafafa;
  box-sizing: border-box;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip a {
  word-break: break-all;
  text-decoration: none;
}
